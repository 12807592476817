<template>
  <div>
    <van-nav-bar title="中药煎配查询" left-text="返回" @click-left="back" fixed left-arrow />
    <div class="large_text">中药煎配查询</div>
    <van-form class="login_form" @submit="startQuery" style="margin-top:80px;">
      <van-field
        readonly
        clickable
        name="picker"
        :value="way"
        label="查询方式"
        placeholder="点击选择查询方式"
        @click="showPicker = true"
        is-link
        arrow-direction="down"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="ways" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
      <van-field label="查询参数" v-model="queryParam" placeholder="请输入值" />

      <div class="form_btn" style="margin: 16px;">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      way: "手机号",
      showPicker: false,
      ways: ["处方号", "姓名", "手机号"],
      queryParam: null,
    };
  },

  created() {},

  methods: {
    onConfirm(value) {
      this.way = value;
      this.showPicker = false;
    },
    // 返回
    back() {
      this.$back();
    },

    startQuery() {
      if (this.queryParam == null || this.queryParam == "") {
        this.$toast.fail("查询参数不能为空");
        return;
      }
      const toast = this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });

      this.$axios
        .get("/data/getRecipeState", {
          params: {
            way: this.way,
            queryParam: this.queryParam,
            userId: this.$user.id,
          },
        })
        .then((res) => {
          toast.clear();
          if (res.code == "ok") {
            this.$router.push({
              name: "cookdetail",
              query: { way: this.way, queryParam: this.queryParam },
            });
          } else {
            this.$dialog
              .alert({
                closeOnPopstate: true,
                message: res.msg,
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          toast.clear();
        });
    },
  },

  mounted() {},
};
</script>

<style lang="less" scope>
.login_form {
  margin-top: 46px;
}

.form_domains {
  position: relative;
  .form_addNew {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .form_del {
    color: #ff0000;
    font-size: 22px;
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

.form_tip {
  text-align: center;
  margin-top: 20px;
  color: #afafaf;
  font-size: 14px;
}

.card__footer {
  padding-top: 10px;
}
.card__tags {
  .van-tag {
    margin-right: 5px;
  }
}
.large_text {
  margin-top: 100px;
  text-align: center;
  font-size: 25px;
  color: #1989fa;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #20a0ff;
}
</style>


